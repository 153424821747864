let origin = "https://future.zhuneng.cn";

//获取商品详情
const getGoodsDetailUrl = `/placeApi/front/pointsMall/goodsDetailById`;

//订单详情页
const getOrderDetailUrl = `/placeApi/front/pointsMall/userPointExchangeDetail`;

//核销
const handleOrderUrl = `/placeApi/front/pointsMall/sceneOrderCompletedByShop`;
//运动打卡列表【分页】
const getSportListUrl = `/placeApi/mng/healthRecord/listPaging`;
//运动打卡审核
const verifyUrl = `/placeApi/mng/healthRecord/audit`;
//运动打卡详情
const getSportDetailUrl = `/placeApi/mng/healthRecord/detailById`;
export {
  verifyUrl,
  getSportListUrl,
  getGoodsDetailUrl,
  getOrderDetailUrl,
  handleOrderUrl,
  getSportDetailUrl,
};
