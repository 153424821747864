import { mapHelper } from "@/utils/utils.js";

const wishType = [
  { key: 1, value: "困难求助" },
  { key: 2, value: "吐槽不满" },
  { key: 3, value: "其他" },
];

const auditStatusMap = [
  { value: "1", text: "审核通过", class: "out" },
  { value: "2", text: "审核驳回", class: "reback" },
  { value: "0", text: "待审核", class: "" },
];
const { map: setAuditStatusMap, setOps: setAuditStatusMapOps } =
  mapHelper.setMap(auditStatusMap);
export { wishType, auditStatusMap, setAuditStatusMap, setAuditStatusMapOps };
